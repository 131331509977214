import React from "react";
import classes from "./ContentRow.module.scss";
import Row from "react-bootstrap/Row";

const ContentRow = ({
  children,
  colour = "cream",
  limitWidth = true,
  expandHeight = false,
  footer = false,
  id,
  padding = true,
}) => {
  let paddingClass = "p-4";
  if (!padding) {
    paddingClass = "p-0";
  }
  return (
    <Row
      id={id}
      className={`${classes.content_row} ${paddingClass}
      } background_${colour} ${expandHeight ? classes.fill_remaining : ""}`}
    >
      <div className={`mx-auto ${limitWidth ? classes.inner_wrapper : ""}`}>
        {children}
      </div>
    </Row>
  );
};

export default ContentRow;
