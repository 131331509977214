import React from "react";
import CandyCaneSpinner from "../../../Components/CandyCaneSpinner/CandyCaneSpinner";
import classes from "./SuspenseLoader.module.scss";
const SuspenseLoader = () => {
  return (
    <div className={`min-vh-100 background_red ${classes.loading}`}>
      <CandyCaneSpinner />
    </div>
  );
};

export default SuspenseLoader;
