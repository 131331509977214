import React, { Fragment, useEffect } from "react";
import classes from "./InvalidRoute.module.scss";

import ContentRow from "../ContentRow/ContentRow";
import HeaderRow from "../HeaderRow/HeaderRow";

import Image from "react-bootstrap/Image";
import sadSantaWebp from "../../images/404/sad-santa.webp";
import sadSantaJpeg from "../../images/404/sad-santa.jpg";

import Footer from "../../Components/ContentRows/Footer/Footer";

const InvalidRoute = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = process.env.REACT_APP_TITLE__404;
  }, []);

  return (
    <Fragment>
      <HeaderRow colour="red" />

      <ContentRow colour="red" id="404" expandHeight>
        <div className={`mx-auto text-center`}>
          <picture>
            <source srcSet={sadSantaWebp} type="image/webp" />
            <source srcSet={sadSantaJpeg} type="image/jpeg" />
            <Image
              src={sadSantaJpeg}
              className={`${classes.sad_santa}`}
              roundedCircle
              fluid
            />
          </picture>
          <div className={`mt-3 h1`}>Ho, Ho, NO!</div>
          <div className={`nonFancyHeading`}>
            <div className={`mt-2 mb-4 h4`}>
              That page doesn't appear to be on Santa's nice list!
            </div>
          </div>
        </div>
      </ContentRow>
      <ContentRow colour="black" limitWidth={false} footer>
        <Footer />
      </ContentRow>
    </Fragment>
  );
};

export default InvalidRoute;
