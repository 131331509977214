import header320webp from "./header320.webp";
import header640webp from "./header640.webp";
import header768webp from "./header768.webp";
import header1280webp from "./header1280.webp";
import header1920webp from "./header1920.webp";
import header2560webp from "./header2560.webp";
import header320jpg from "./header320.jpg";
import header640jpg from "./header640.jpg";
import header768jpg from "./header768.jpg";
import header1280jpg from "./header1280.jpg";
import header1920jpg from "./header1920.jpg";
import header2560jpg from "./header2560.jpg";

const webp = {
  320: header320webp,
  640: header640webp,
  768: header768webp,
  1280: header1280webp,
  1920: header1920webp,
  2560: header2560webp,
};
const jpg = {
  320: header320jpg,
  640: header640jpg,
  768: header768jpg,
  1280: header1280jpg,
  1920: header1920jpg,
  2560: header2560jpg,
};

export { webp, jpg };
