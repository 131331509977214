import React from "react";
//import classes from "./HeaderRow.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../Components/ContentRows/Header/Header";

const HeaderRow = ({ colour = "red", hero, orderButton }) => {
  return (
    <Row className={`background_${colour}`}>
      <Col className="mx-0 px-0">
        <Header hero={hero} orderButton={orderButton} />
      </Col>
    </Row>
  );
};

export default HeaderRow;
