import React from "react";
import { webp, jpg } from "../../../images/header/images";

import NavBar from "./NavBar/NavBar";

const Header = ({ hero = true, orderButton = true }) => {
  return (
    <div>
      <NavBar orderButton={orderButton} />
      {hero ? (
        <>
          <picture>
            <source
              type="image/webp"
              srcSet={`${webp[320]} 320w, ${webp[640]} 640w, ${webp[768]} 768w, ${webp[1280]} 1280w, ${webp[1920]} 1920w, ${webp[2560]} 2560w`}
            />
            <source
              type="image/jpeg"
              srcSet={`${jpg[320]} 320w, ${jpg[640]} 640w, ${jpg[768]} 768w, ${jpg[1280]} 1280w, ${jpg[1920]} 1920w, ${jpg[2560]} 2560w`}
            />
            <img
              src={jpg[768]}
              className="img-fluid w-100 mt-6"
              alt="Santa's Answers Logo made from Child's letter blocks, on a Christmas-decorated wooden shelf beside a Cream envelope with a red ribbon bow and the words 'With Love from Santa' hand-written in red ink"
            />
          </picture>
          <div className={`candy_cane full_width tall cc_shadow`}></div>
        </>
      ) : (
        <div className={`pt-6`} />
      )}
    </div>
  );
};

export default Header;
