import { configureStore } from "@reduxjs/toolkit";
import contactReducer from "./reducers/contactReducer";
import testimonialsReducer from "./reducers/testimonialsReducer";
import orderReducer from "./reducers/orderReducer";
import uiReducer from "./reducers/uiReducer";

export const store = configureStore({
  reducer: {
    contact: contactReducer,
    testimonials: testimonialsReducer,
    order: orderReducer,
    ui: uiReducer,
  },
});
