import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFacebookReviews = createAsyncThunk(
  "testimonials/getFacebookReviews",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}facebook.php`
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState: { facebook: [], dataReceived: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFacebookReviews.fulfilled, (state, action) => {
      state.facebook = action.payload;
      state.dataReceived = true;
    });
    builder.addCase(getFacebookReviews.pending, (state, action) => {
      state.dataReceived = false;
    });
    builder.addCase(getFacebookReviews.rejected, (state, action) => {
      state.dataReceived = false;
    });
  },
});

export default testimonialsSlice.reducer;
