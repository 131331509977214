import React from "react";
import classes from "./Logo.module.scss";
import { HashLink } from "react-router-hash-link";

const Logo = () => {
  return (
    <HashLink smooth to="/#" className={`${classes.logo}`}>
      <span className={`${classes.text_logo}`}>S</span>
      <span className={`${classes.text_logo} ${classes.tilt_1}`}>A</span>
      <span className={`${classes.text_logo}`}>N</span>
      <span className={`${classes.text_logo} ${classes.tilt_2}`}>T</span>
      <span className={`${classes.text_logo}`}>A</span>
      <span className={`${classes.text_logo} ${classes.tilt_1}`}>S</span>
      &nbsp;
      <span className={`${classes.text_logo}`}>A</span>
      <span className={`${classes.text_logo} ${classes.tilt_1}`}>N</span>
      <span className={`${classes.text_logo}`}>S</span>
      <span className={`${classes.text_logo} ${classes.tilt_2}`}>W</span>
      <span className={`${classes.text_logo}`}>E</span>
      <span className={`${classes.text_logo} ${classes.tilt_2}`}>R</span>
      <span className={`${classes.text_logo}`}>S</span>
    </HashLink>
  );
};

export default Logo;
