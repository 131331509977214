import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./MailingListSubscribe.module.scss";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import CandyCaneSpinner from "../../../CandyCaneSpinner/CandyCaneSpinner";
import { submitContactForm } from "../../../../store/reducers/contactReducer";

const MailingListSubscribe = () => {
  const dispatch = useDispatch();

  const firstName = useSelector((state) => state.contact.firstName);
  const surName = useSelector((state) => state.contact.surName);
  const email = useSelector((state) => state.contact.email);
  const signedUp = useSelector((state) => state.contact.signedUpToMailingList);

  const postState = useSelector((state) => state.contact.postState);
  const postError = useSelector((state) => state.contact.postError);

  const [validated, setValidated] = useState(false);

  const emailIsValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const firstnameIsValid = firstName !== "";
  const surnameIsValid = surName !== "";

  const handleSubmit = async (e) => {
    if (firstnameIsValid && surnameIsValid && emailIsValid) {
      dispatch(submitContactForm({ subscribe: true, footer: true }));
    } else {
      setValidated(true);
    }
  };

  let form = (
    <>
      <Form noValidate className={`${classes.contact_form} mx-auto`}>
        <div className={`h4 mb-3 ${classes.signup_header}`}>
          <u>Sign up to Santa's list!</u>
        </div>
        <Form.Group>
          <Form.Control
            isInvalid={!firstnameIsValid && validated}
            isValid={firstnameIsValid && validated}
            className={`${
              (firstnameIsValid && validated) || !validated ? "mb-2" : "mb-0"
            } `}
            id="firstname"
            autoComplete="given-name"
            size="sm"
            type="text"
            placeholder="Your firstname"
            //size="sm"
            value={firstName}
            onChange={(e) => {
              dispatch({
                type: "contact/updateField",
                field: "firstName",
                input: e.target.value,
              });
            }}
          />
          <Form.Control.Feedback type="invalid" className={`mb-2 mt-0`}>
            Please enter your first name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={!surnameIsValid && validated}
            isValid={surnameIsValid && validated}
            className={`${
              (surnameIsValid && validated) || !validated ? "mb-2" : "mb-0"
            } `}
            autoComplete="family-name"
            size="sm"
            value={surName}
            onChange={(e) => {
              dispatch({
                type: "contact/updateField",
                field: "surName",
                input: e.target.value,
              });
            }}
            type="text"
            placeholder="Your surname"
          />
          <Form.Control.Feedback type="invalid" className={`mb-2 mt-0`}>
            Please enter your surname.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={!emailIsValid && validated}
            isValid={emailIsValid && validated}
            className={`${
              (emailIsValid && validated) || !validated ? "mb-4" : "mb-0"
            } `}
            autoComplete="email"
            size="sm"
            value={email}
            onChange={(e) => {
              dispatch({
                type: "contact/updateField",
                field: "email",
                input: e.target.value,
              });
            }}
            type="email"
            placeholder="Your email"
          />
          <Form.Control.Feedback type="invalid" className={`mt-0 mb-4`}>
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="outline-sa-cream"
          type="button"
          className={`mx-auto w-100 `}
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </Form>
    </>
  );
  if (postState === "busy") {
    form = (
      <div className={`mx-auto`}>
        <CandyCaneSpinner />
      </div>
    );
  }
  if (signedUp) {
    form = (
      <div className={`${classes.thank_you} mx-auto`}>
        Thanks for signing up to our mailing list, {firstName}!
      </div>
    );
  }
  if (postState === "error") {
    form = (
      <div>
        <p>Unfortunately, the following error while submitting your data...</p>
        <p className={`lead`}>{postError}</p>
      </div>
    );
  }

  return <div className={`${classes.mailing_list_wrapper}`}>{form}</div>;
};

export default MailingListSubscribe;
