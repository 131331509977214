import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";

import Main from "./Containers/Layout/Main";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
//? Main SCSS file - imports everything else...
import "./SCSS/index.scss";

ReactGA.initialize(
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "debug_mode: true"
    : ""
);

const options = {
  autoConfig: true,
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
};
ReactPixel.init(process.env.REACT_APP_FACEBOOK_ANALYTICS_ID, options);
ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");

const tiktokOptions = {
  autoConfig: true,
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
};
TiktokPixel.init(process.env.REACT_APP_TIKTOK_ANALYTICS_ID, {}, tiktokOptions);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Main />
    </BrowserRouter>
  </Provider>
);
