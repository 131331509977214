import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ReactGA from "react-ga4";

export const submitContactForm = createAsyncThunk(
  "contact/submitContactForm",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}contactForm.php`,
        {
          customerEmail: state.contact.email,
          customerName: state.contact.firstName,
          customerSurname: state.contact.surName,
          customerMessage: state.contact.message,
          contactSubscribe: data.subscribe,
          contactFooter: data.footer,
        }
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
const contactSlice = createSlice({
  name: "contact",
  initialState: {
    messageReceived: false,
    signedUpToMailingList: false,
    firstName: "",
    surName: "",
    email: "",
    message: "",
    postState: "idle",
    postError: "",
  },
  reducers: {
    updateField(state, action) {
      state[action.field] = action.input;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitContactForm.fulfilled, (state, action) => {
      let method = "Contact Form";
      if (action.payload.from_footer) {
        method = "Footer";
      } else {
        state["messageReceived"] = true;
      }
      if (action.payload.subscribe === true) {
        state["signedUpToMailingList"] = true;
        ReactGA.event("subscribe_to_email_list", {
          method: method,
        });
      }

      state["postState"] = "idle";
    });
    builder.addCase(submitContactForm.pending, (state, action) => {
      state["postState"] = "busy";
    });
    builder.addCase(submitContactForm.rejected, (state, action) => {
      state["postState"] = "error";
      state["postError"] = action.payload;
    });
  },
});

export default contactSlice.reducer;
