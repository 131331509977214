import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import ReactPixel from "react-facebook-pixel";

const initialState = {
  checkout: {
    basketState: "idle",
    basketError: "",
    deliveryCountry: "Ireland",
    price: { stripeId: "santasanswers" },
    paymentState: "idle",
    paymentError: "",
  },
  customer: {
    firstname: "",
    surname: "",
    address_1: "",
    address_2: "",
    address_3: "",
    address_4: "",
    address_5: "",
    email: "",
    subscribe_email: true,
  },
  initialInfoEntered: false,
  checkoutEnabled: false,
  isSharedSurname: false,
  isSharedLocation: true,
  allSiblings: true,
  nonSiblingRelationship: "",
  otherSiblings: false,
  otherSiblingsDetails: "",
  sharedLocation: "",
  sharedFullAddress: "",
  sharedSurname: "",
  letters: [],
  currentLetter: 0,
  resumeState: "idle",
  resumeError: "",
  saveCode: "",
  saveName: "",
  saveEmail: "",
  saveState: "idle",
  savedPage: "",
  saveError: "",
  resumeEmail: "",
  resumeCode: "",
};

export const attemptResume = createAsyncThunk(
  "order/attemptResume",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}resumeOrder.php`,
        {
          code: state.order.resumeCode,
          email: state.order.resumeEmail,
        }
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const saveData = createAsyncThunk(
  "order/saveData",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}saveOrder.php`,
        {
          code: state.order.saveCode,
          order: state.order,
          email: state.order.saveEmail,
          firstname: state.order.saveName,
          subscribe: true,
        }
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const getPrice = createAsyncThunk(
  "order/getPrice",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}getPrice.php`,
        {
          lettersInOrder: state.order.letters.map((letter) => {
            return letter.package_type;
          }),
          stripeId: state.order.checkout.price.stripeId,
          destination: state.order.checkout.deliveryCountry,
        }
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const submitOrder = createAsyncThunk(
  "order/submitOrder",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}submitOrder.php`,
        {
          orderId: state.order.orderId,
          customer: state.order.customer,
          letters: state.order.letters,
          country: state.order.checkout.deliveryCountry,
          finalPrice: state.order.checkout.price.finalPrice,
          stripeID: state.order.checkout.price.stripeId,
          otherSiblingsDetails: state.order.otherSiblingsDetails,
          nonSiblingRelationship: state.order.nonSiblingRelationship,
          allSiblings: state.order.allSiblings,
          otherSiblings: state.order.otherSiblings,
          isSharedLocation: state.order.isSharedLocation,
        }
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    reset(state, action) {
      return initialState;
    },
    resetSaveProgress(state, action) {
      state.saveState = "idle";
    },
    resetResumeState(state, action) {
      state.resumeState = "idle";
    },

    chooseChild(state, action) {
      state.currentLetter = action.letter;
    },
    setInitialViewed(state, action) {
      state.initialInfoEntered = action.initialInfo;
    },
    checkoutEnable(state, action) {
      state.checkoutEnabled = action.checkoutEnabled;
    },
    setPaymentState(state, action) {
      state.checkout.paymentState = action.input;
      if (action.input === "error") {
        state.checkout.paymentError = action.message;
      }
    },
    setNumberOfLetters(state, action) {
      if (
        //action.input === "" ||
        action.input === "0" ||
        action.input === null
      ) {
        action.input = "1";
      }
      if (action.input > 20) {
        action.input = 20;
      }

      if (action.input >= state.letters.length) {
        for (
          let index = state.letters.length;
          index < action.input && index < 20;
          index++
        ) {
          state.letters.push({
            firstname: "",
            surname: "",
            age: "",
            gender: "",
            package_type: "",
            letter_style: "",
            location: "",
            env_address: "",
            parents_name: "",
            present: "",
            delivery_method: "",
            behaviour: "",
            school: "",
            teacher: "",
            school_achievements: "",
            hobbies: "",
            pets: "",
            traditions: "",
            misc: "",
            complete: false,
          });
        }
      } else {
        for (let index = state.letters.length; index > action.input; index--) {
          state.letters.pop();
        }
      }
    },

    updateDeliveryCountry(state, action) {
      state.checkout.deliveryCountry = action.input;
    },
    updateCustomerField(state, action) {
      state.customer[action.field] = action.input;
    },
    updateField(state, action) {
      if (action.letter == null) {
        state[action.field] = action.input;
        if (action.field === "sharedSurname") {
          state.letters.forEach((letter) => {
            letter.surname = action.input;
          });
        }
        if (action.field === "sharedLocation") {
          state.letters.forEach((letter) => {
            letter.location = action.input;
          });
        }
        if (action.field === "sharedFullAddress") {
          state.letters.forEach((letter) => {
            letter.env_address = action.input;
          });
        }
      } else {
        state.letters[action.letter][action.field] = action.input;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitOrder.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.checkout.paymentState = "error";
        state.checkout.paymentError = action.payload.message;
      } else {
        state.orderId = action.payload.orderID;
        state.customer.id = action.payload.custID;
        state.letters.forEach((letter, index) => {
          letter.letterId = action.payload.letters[index];
        });
        state.checkout.stripeSecret = action.payload.stripeSecret;
        state.checkout.paymentState = "idle";
      }
    });
    builder.addCase(submitOrder.rejected, (state, action) => {
      console.log(action.payload.message);
    });
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.saveCode = action.payload;
      state.saveState = "success";
      ReactGA.event("subscribe_to_email_list", { method: "saved_order" });
      ReactGA.event("saved_order", { code: action.payload });
      ReactPixel.trackCustom("saved_order", { code: action.payload });
      TiktokPixel.track("Subscribe", { method: "saved_order" });
      TiktokPixel.track("Saved Order", { code: action.payload });
    });
    builder.addCase(saveData.pending, (state, action) => {
      state.saveState = "busy";
    });
    builder.addCase(saveData.rejected, (state, action) => {
      state.saveState = "error";
      state.saveError = action.payload;
    });
    builder.addCase(attemptResume.fulfilled, (state, action) => {
      if (action.payload["code"]) {
        state.savedPage = action.payload["order"].savedPage;
        state.initialInfoEntered = action.payload["order"].initialInfoEntered;
        state.checkoutEnabled = action.payload["order"].checkoutEnabled;
        state.isSharedSurname = action.payload["order"].isSharedSurname;
        state.isSharedLocation = action.payload["order"].isSharedLocation;
        state.allSiblings = action.payload["order"].allSiblings;
        state.nonSiblingRelationship =
          action.payload["order"].nonSiblingRelationship;
        state.otherSiblings = action.payload["order"].otherSiblings;
        state.otherSiblingsDetails =
          action.payload["order"].otherSiblingsDetails;
        state.sharedLocation = action.payload["order"].sharedLocation;
        state.sharedFullAddress = action.payload["order"].sharedFullAddress;
        state.sharedSurname = action.payload["order"].sharedSurname;
        state.letters = action.payload["order"].letters;
        state.currentLetter = action.payload["order"].currentLetter;
        state.saveName = action.payload["order"].saveName;
        state.saveEmail = action.payload["order"].saveEmail;
        state.saveCode = action.payload["code"];
        state.resumeState = "success";

        ReactGA.event("resumed_order", { code: action.payload });
        ReactPixel.trackCustom("resumed_order", { code: action.payload });
      } else {
        state.resumeError = action.payload;
        state.resumeState = "error";
      }
    });
    builder.addCase(attemptResume.pending, (state, action) => {
      state.resumeState = "busy";
    });
    builder.addCase(attemptResume.rejected, (state, action) => {
      state.resumeError = action.payload;
      state.resumeState = "error";
    });
    builder.addCase(getPrice.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.checkout.basketState = "error";
        state.checkout.basketError = action.payload.message;
      } else {
        state.checkout.basketState = "success";
        state.checkout.price = action.payload;
      }
    });
    builder.addCase(getPrice.pending, (state, action) => {
      state.checkout.basketState = "busy";
    });
    builder.addCase(getPrice.rejected, (state, action) => {
      state.checkout.basketState = "error";
      state.checkout.basketError = action.payload;
    });
  },
});

export default orderSlice.reducer;
