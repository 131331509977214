import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    restartModalVisible: false,
    saveModalVisible: false,
    checkoutEnabled: false,
    orderCompleted: false,
    name: "",
    orderNumber: "",
  },
  reducers: {
    setModalVisible(state, action) {
      switch (action.modal) {
        case "restart":
          state.restartModalVisible = action.visible;
          break;
        case "save":
          state.saveModalVisible = action.visible;
          break;
        default:
          break;
      }
    },
    successfulPurchase(state, action) {
      state.orderCompleted = true;
      state.name = action.name;
      state.orderNumber = `SA${
        parseInt(10570000) + parseInt(action.eCommerceData.orderNumber)
      }`;
      state.eCommerceData = action.eCommerceData;
    },
  },
});

export default uiSlice.reducer;
