import React from "react";
import classes from "./NavBar.module.scss";
import { useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../../../../global/functions";

const NavBar = ({ orderButton }) => {
  let orderNowVisible = orderButton;
  const orderPage = useSelector((state) => state.order.savedPage);
  let orderLink = "/Order/" + orderPage + "/#";

  if (orderPage === "") {
    orderLink = "/Order#";
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`background_black ${classes.navbar}`}
      variant="dark"
      fixed="top"
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="me-auto"
      />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link eventKey="1" as={HashLink} smooth to="/#">
            Home
          </Nav.Link>
          {/*<Nav.Link
            eventKey="2"
            as={HashLink}
            smooth
            to="/#AboutUs"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            About
          </Nav.Link>*/}
          <Nav.Link
            eventKey="3"
            as={HashLink}
            smooth
            to="/Letters#"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Our Letters
          </Nav.Link>
          <Nav.Link
            eventKey="4"
            as={HashLink}
            smooth
            to="/Extras#"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Our Extras
          </Nav.Link>
          <Nav.Link
            eventKey="5"
            as={HashLink}
            smooth
            to="/Packages#"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Our Packages
          </Nav.Link>
          {/*<Nav.Link
            eventKey="6"
            as={HashLink}
            smooth
            to="/#Discounts"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Discounts
          </Nav.Link>
          <Nav.Link
            eventKey="7"
            as={HashLink}
            smooth
            to="/#Delivery"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Delivery
          </Nav.Link>*/}
          <Nav.Link
            eventKey="8"
            as={HashLink}
            smooth
            to="/Contact_Us#"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            Contact Us
          </Nav.Link>
          <Nav.Link
            eventKey="9"
            as={HashLink}
            smooth
            to="/FAQ#"
            scroll={(el) => {
              scrollWithOffset(el);
            }}
          >
            FAQ
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav>
        <Nav.Link eventKey="10" as={HashLink} smooth to={orderLink}>
          <span
            className={`${
              orderNowVisible ? "" : "d-none"
            } lead border border-2 border-danger p-2 ms-0 ms-lg-2 ${
              classes.order_now
            }`}
          >
            Order Now
          </span>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
