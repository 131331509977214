import React from "react";
import classes from "./SocialIcons.module.scss";

import { facebook, instagram, x, email } from "../../../../data/links";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const SocialIcons = () => {
  return (
    <div className={`${classes.socials_wrapper}`}>
      <a target="_blank" href={facebook} rel="noopener noreferrer">
        <FontAwesomeIcon
          icon={brands("facebook-square")}
          //size="4x"
          transform={{ rotate: -10 }}
          className={`${classes.icon} ${classes.facebook}`}
        />
      </a>
      <a target="_blank" href={instagram} rel="noopener noreferrer">
        <FontAwesomeIcon
          icon={brands("instagram")}
          //size="4x"
          transform={{ rotate: 10 }}
          className={`${classes.icon} ${classes.facebook}`}
        />
      </a>
      <a target="_blank" href={x} rel="noopener noreferrer">
        <FontAwesomeIcon
          icon={brands("x-twitter")}
          //size="4x"
          transform={{ rotate: -10 }}
          className={`${classes.icon} ${classes.facebook}`}
        />
      </a>
      <a target="_blank" href={email} rel="noopener noreferrer">
        <FontAwesomeIcon
          icon={solid("at")}
          //size="4x"
          transform={{ rotate: 10 }}
          className={`${classes.icon} ${classes.facebook}`}
        />
      </a>
    </div>
  );
};

export default SocialIcons;
