import React from "react";
import classes from "./Footer.module.scss";

import { email } from "../../../data/links";

import SocialIcons from "./SocialIcons/SocialIcons";
import MailingListSubscribe from "./MailingListSubscribe/MailingListSubscribe";
import Logo from "./Logo/Logo";

const Footer = () => {
  return (
    <div>
      <div className={`py-3 ${classes.content_grid}`}>
        <div className={`small ${classes.address}`}>
          <strong>Santa's Answers</strong>
          <br />
          Unit 25D
          <br />
          Jingle Bell Business Park
          <br />
          North Pole
          <p />
          <a href={email}>info@santasanswers.com</a>
        </div>
        <div className={`text-center ${classes.signup}`}>
          <MailingListSubscribe />
        </div>
        <div className={`${classes.socials}`}>
          <SocialIcons />
        </div>
      </div>

      <div className={`candy_cane full_width my-4`}></div>
      <div className={`small text-center`}>
        <Logo />
        <br />
        &copy; Santa's Answers {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
